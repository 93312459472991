<template>
  <div class="main">
    <Table :columns="columns" :data="data" :loading="loading">
		<template slot="app_info" slot-scope="{row}">
			<div style="display: flex; align-items: center; justify-content: center;">
				<img :src="'//'+row.icon" style="width: 80px; height: 80px;margin-right: 4px;">
				<div>{{ row.name }}</div>
			</div>
		</template>
		<template slot="operate" slot-scope="{row}">
			<Button @click="unbind(row.id)">解绑</Button>
		</template>
	</Table>
	<div class="ser-page">
		<Page :total="search.total" @on-change="pageChange" @on-page-size-change="pageSizeChange" :current="search.page" :page-size="search.pageSize" :page-size-opts="[15,30,50]" show-total show-sizer></Page>
	</div>
  </div>
</template>

<script>
	export default {
		name: "Authorize",
		data(){
			return{
				search: {
					total: 0,
					page: 1,
					pageSize: 15
				},
				columns: [
					{ title: 'ID', key: 'id', width: 80, align: 'center' },
					{ title: '应用', slot: 'app_info', minWidth: 200, align: 'center' },
					{ title: '商户号', key: 'store_id', minWidth: 80, align: 'center' },
					{ title: '授权站点', key: 'auth_site', minWidth: 200, align: 'center' },
					{ title: '授权时间', key: 'auth_time', minWidth: 150, align: 'center' },
					{ title: '授权时间', slot: 'operate', width: 100, align: 'center' }
				],
				data: [],
				loading: false
			}
		},
		created() {
			this.init()
		},
		methods:{
			// 初始化 数据
			init(){
				let _this = this
				this.loading = true
				this.requestApi('/adm/get_authorization_list', this.search ).then((res)=>{
					if(res.status){
						this.loading = false
						_this.data = res.data.data
						_this.search.total = res.data.total
					}else{
						_this.alertErr(res.msg)
					}
				})
			},
			// 页码改变事件
			pageChange(page){
				this.search.page = page
				this.init()
			},
			// 每页条数改变事件
			pageSizeChange(pageSize){
				this.search.pageSize = pageSize
				this.init()
			},
			// 解除绑定 授权关系
			unbind(id){
				let _this = this 
				this.requestApi('/adm/del_authorization_list', { id: id }).then((res)=>{
					if(res.status){
						_this.alertSucc('解绑成功')
						_this.init()
					}else{
						_this.alertErr(res.msg)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.main{
		background-color: #fff;
		padding: 12px;
	}
	.ser-page{
		padding: 12px;
		text-align: right;
	}
</style>
